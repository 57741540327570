/*------------------------------------*\
  #LINKS
\*------------------------------------*/

a {
    color: inherit;
    text-decoration: none;
}

p a {
    display: inline-flex;
    overflow: hidden;
    text-indent: 0;

    &:before {
        content: '\00a0';
        display: inline-block;
        width: 1.4rem;
        margin-right: 0.2rem;
        margin-left: 0.2rem;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAyOSAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik02LjM3ODI3IDMuMTU1MTNMNi4wMTE1IDIuNjE4NDlMNi4wMTE1IDIuNjE4NDlMNi4zNzgyNyAzLjE1NTEzWk0xNS4wMzk0IDAuNzU5MTY4TDE1LjAwMDYgMS40MDgwMUwxNS4wMzk0IDAuNzU5MTY4Wk0yMy4zNTMgNC4xNzA4NkwyMi45MjQ5IDQuNjU5OTNWNC42NTk5M0wyMy4zNTMgNC4xNzA4NlpNMjcuODM0NCAxMS45NjAyTDI4LjcyNDMgNC41MDc1NkwyMS44MjUyIDcuNDYzMTdMMjcuODM0NCAxMS45NjAyWk0xLjYxODYzIDEwLjU1NEMyLjUxOTIxIDcuNzYxMzggNC4zMjI1NyA1LjM0NzQxIDYuNzQ1MDQgMy42OTE3N0w2LjAxMTUgMi42MTg0OUMzLjM1MSA0LjQzNjgyIDEuMzcwNDUgNy4wODc5OCAwLjM4MTM3MyAxMC4xNTQ5TDEuNjE4NjMgMTAuNTU0Wk02Ljc0NTA0IDMuNjkxNzdDOS4xNjc1MSAyLjAzNjEyIDEyLjA3MTYgMS4yMzI3NCAxNS4wMDA2IDEuNDA4MDFMMTUuMDc4MiAwLjExMDMyOUMxMS44NjE1IC0wLjA4MjE1NTMgOC42NzIgMC44MDAxNiA2LjAxMTUgMi42MTg0OUw2Ljc0NTA0IDMuNjkxNzdaTTE1LjAwMDYgMS40MDgwMUMxNy45Mjk1IDEuNTgzMjcgMjAuNzE3MSAyLjcyNzIzIDIyLjkyNDkgNC42NTk5M0wyMy43ODEyIDMuNjgxNzlDMjEuMzU2NSAxLjU1OTE3IDE4LjI5NSAwLjMwMjgxMyAxNS4wNzgyIDAuMTEwMzI5TDE1LjAwMDYgMS40MDgwMVpNMjIuOTI0OSA0LjY1OTkzQzIzLjcwOTQgNS4zNDY2OCAyNC40MDQ4IDYuMTE5MTQgMjUuMDAwNiA2Ljk1ODkxTDI2LjA2MDkgNi4yMDY2N0MyNS40MDY1IDUuMjg0MzcgMjQuNjQyNyA0LjQzNTk5IDIzLjc4MTIgMy42ODE3OUwyMi45MjQ5IDQuNjU5OTNaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4=");
        background-repeat: no-repeat;
        background-position: center 55%;
        background-size: contain; //  width="29" height="12"
        --transform: rotate(360deg);
        transform-origin: center 100%;
    }

    &:hover {
        transform: skewX(-18deg) translateX(0.22ch);

        &:before {
            animation: to-transform 0.6s $trans-func--ease-in-out both;
        }
    }

    &.is-reverse {
        &:before {
            --from-transform: rotate(0deg) scaleX(-1);
            --transform: rotate(-360deg) scaleX(-1);
            transform: var(--from-transform);
            margin-right: 0.4rem;
        }

        &:hover {
            &:before {
                animation: from-to-transform 0.6s $trans-func--ease-in-out both;
            }
        }
    }
}