/*------------------------------------*\
  #PAGE-GRID
\*------------------------------------*/

/**
 * Create a flexible page grid
 */


// Provide a grid container with a dedicated setup
// to define the overall page grid
.page-grid {
    display: grid;
    grid-template-columns: repeat(var(--grid-cols), minmax(0, 1fr));
    column-gap: var(--column-gap);
    align-items: start;
    padding: 0 var(--column-gap);

    @include respond-to(lg) {
        grid-template-areas: "left left left center center center right right right";
    }
}

.page-grid__full {
    grid-column: 1 / span var(--grid-cols);

    @include responsive-selector(false, lg) {
        grid-column: 1 / span var(--grid-cols);
        grid-row: 1 / span 1;
    }
}

.page-grid__left {
    grid-column: 1 / span 7;

    @include respond-to(lg) {
        grid-area: left;
        grid-row: 1 / span 1;
    }

    @include responsive-selector(false, lg) {
        grid-area: left;
        grid-row: 1 / span 1 !important; // overule mobile styles for work
    }
}

.page-grid__center {
    grid-column: 2 / span 7;

    @include respond-to(lg) {
        grid-area: center;
        grid-row: 1 / span 1;
    }

    @include responsive-selector(false, lg) {
        grid-area: center;
        grid-row: 1 / span 1 !important; // overule mobile styles for work
    }
}

.page-grid__right {
    grid-column: 3 / span 7;

    @include respond-to(lg) {
        grid-area: right;
        grid-row: 1 / span 1;
    }

    @include responsive-selector(false, lg) {
        grid-area: right;
        grid-row: 1 / span 1 !important; // overule mobile styles for work
    }
}