/*------------------------------------*\
  #GRID-PREVIEW
\*------------------------------------*/

// Grid overlay
.grid-preview {
    --bg-grid-toggle: var(--border-default);
    --bg-grid-row: transparent;
    --bg-grid-row-border: var(--border-light);

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: map-get($z-index, grid-preview);
    pointer-events: none;
}

// hidden checkbox
.grid-preview__checkbox {
    @include hide();
}

// label for hidden checkbox
.grid-preview__toggle {
    position: absolute;
    top: 0;
    right: 0;
    z-index: map-get($z-index, grid-toggle);
    cursor: pointer;
    pointer-events: all;
    display: grid;
    padding: var(--body-p);
    text-indent: -200vw; // have text inside label to pass lighthouse test

    &:after {
        content: '';
        transition: transform $trans-time--s $trans-func--ease-out;
        border-radius: 100%;
        width: var(--grid-toggle-w);
        height: var(--grid-toggle-w);
        margin: var(--grid-toggle-my) var(--grid-toggle-mx);
        border: 1px solid var(--bg-grid-toggle);
    }

    input:checked + & {
        &:after {
            background: var(--bg-grid-toggle);
            transform: scale(0.6);
        }
    }

    @include respond-to(lg) {
        top: auto;
        right: auto;
        bottom: 0;
        left: 0;
    }
}

.grid-preview__container {
    height: 100%;
    display: none;

    // Make grid visible
    // .grid-preview__toggle:hover ~ &,
    input:checked ~ & {
        display: block;
    }
}

.grid-preview__text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: var(--body-p);
    justify-content: end;
    font-size: 0.8em;

    span {
        pointer-events: all;
        cursor: pointer;

        // &:nth-child(1),
        &:nth-child(8),
        &:nth-child(9) {
            visibility: hidden;
        }
    }

    @include respond-to(md) {
        font-size: inherit;

        span {
            // &:nth-child(1),
            &:nth-child(8),
            &:nth-child(9) {
                visibility: inherit;
            }
        }
    }
}

.grid-preview__margin {
    margin: var(--body-p) 0;
    border-top: solid 1px var(--bg-grid-row-border);
    border-bottom: solid 1px var(--bg-grid-row-border);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    @include respond-to(lg) {
        margin: var(--body-p);
        border: solid 1px var(--bg-grid-row-border);
    }
}

.grid-preview__grid {
    display: grid;
    height: 100%;
    margin-top: 0 !important;

    div {
        background-color: var(--bg-grid-row);
        border-left: solid 1px var(--bg-grid-row-border);
        border-right: solid 1px var(--bg-grid-row-border);
        height: 100%;
    }
}

