/*------------------------------------*\
  #SCUTTLE

  Mask content in a scrolling window
\*------------------------------------*/

// Add scrolling number which sticks to the top
.scuttle {
    --scuttle-pt: calc(100vh - var(--scuttle-h) - var(--scuttle-pb));
    --scuttle-w: 100%;
    display: grid;
    min-height: 100vh;
    max-width: 100%;
    position: relative;
    pointer-events: none;

    p,
    img {
        pointer-events: all;
    }

    @include respond-to(lg) {
        --scuttle-pt: calc((100vh - var(--scuttle-h)) / 2);
        --scuttle-pb: calc(100vh - var(--scuttle-h) - var(--scuttle-pt));
    }
}

.scuttle--hero {
    --scuttle-h: var(--scuttle-hero-h);
    --scuttle-pt: calc((100vh - var(--scuttle-h)) / 2);
    --scuttle-pb: calc(100vh - var(--scuttle-h) - var(--scuttle-pt));
}

.scuttle--hero,
.scuttle--phone {
    @include respond-to(lg) {
        --scuttle-h: var(--scuttle-phone-h);
        --scuttle-w: calc(var(--scuttle-h) / var(--scuttle-phone-ratio));
        width: var(--scuttle-w);
        place-self: start center;
    }
}

.scuttle--wide {
    --scuttle-h: min(50vh, 100vh - 20rem);

    @include respond-to(lg) {
        --scuttle-h: min(34vw, 100vh - 20rem);
        --scuttle-w: 100%;
        --scuttle-pb: max(3vh, 2rem);
        --scuttle-pt: calc(100vh - var(--scuttle-h) - var(--scuttle-pb));
        width: var(--scuttle-w);
        place-self: start center;
    }

}

.scuttle--tower {
    @include respond-to(lg) {
        --scuttle-h: min(100vw, 100vh - 11rem);
        --scuttle-w: 100%;
        --scuttle-pb: max(3vh, 2rem);
        --scuttle-pt: calc(100vh - var(--scuttle-h) - var(--scuttle-pb));
        width: var(--scuttle-w);
        place-self: start center;
    }
}

.scuttle__background,
.scuttle__content,
.scuttle__cover {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
}

.scuttle__content {
    padding: var(--scuttle-pt) 0 var(--scuttle-pb);
    position: relative;
}

// Create a solid cover for top and bottom
.scuttle__background,
.scuttle__cover {
    height: 100vh;
    position: sticky;
    top: 0;
}

.scuttle__stick {
    position: sticky;
    top: var(--scuttle-pt);
}

.scuttle__background {
    > * {
        position: absolute;
        top: var(--scuttle-pt);
        bottom: var(--scuttle-pb);
        left: 0;
        right: 0;
    }
}

.scuttle__cover {
    display: grid;
    grid-template-rows: var(--scuttle-pt) 1fr var(--scuttle-pb);
    overflow: hidden;
    outline: 1px solid var(--bg-default); // prevent blitzer

    // Add cover for top and bottom part
    &:before,
    &:after {
        content: '';
        grid-row: 1 / span 1;
        background: var(--bg-default);
    }

    &:after {
        grid-row: 3 / span 1;
    }
}

// All mask-image approaches let to subpixel render issues
// Therefore we create an item with a rounded border
.scuttle__cover-roundness {
    --border-offset: 2.2rem; // magic numbere
    grid-row: 2 / span 1;
    border: var(--border-offset) solid var(--bg-default);
    margin: calc(var(--border-offset) * -1);
    border-radius: calc(var(--scuttle-radius) + var(--border-offset));
}
