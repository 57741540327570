/*------------------------------------*\
  #MEDIA-BOX
\*------------------------------------*/

/**
 * Provide a media container in order to display media (usually images)
 * cropped to certain ratios and provide a placeholder box while images
 * are still loading
 *
 * 1. Set up a positioning context in which the image can sit.
 * 2. This is the crucial part: where the cropping happens.
 * 3. Default aspect ratio
 * 4. Remove blitzer
 * 5. Include aspect ratio hack for legacy devices
 */

// media box containing images or videos
// by default aspect ratio is defined by content
.media-box {
    position: relative; // [1]
    overflow: hidden; // [2]
    --aspect-ratio: 0.75; // [3]
    @include aspect-ratio(); // [4]
    background: var(--bg-media-box);

    img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: 50% 50%;
    }
}

.media-box--l {
    --aspect-ratio: 0.8 !important; // 5/4
}

.media-box--c {
    --aspect-ratio: 1 !important;
}

.media-box--p {
    --aspect-ratio: 1.333 !important; // 3/4
}

.media-box--p2 {
    --aspect-ratio: 1.41 !important; // din
}

.media-box--p3 {
    --aspect-ratio: 1.5 !important; // 2/3
}

.media-box--modal {
    background: transparent;

    img {
        object-fit: cover;
        object-position: center center;
    }

    &:after {
        display: none;
    }

    @include respond-to(lg) {
        img {
            object-fit: contain;
        }
    }
}

.media-box--scuttle {
    height: var(--scuttle-h);

    &:after {
        display: none;
    }
}