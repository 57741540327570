/*------------------------------------*\
  #colophon SECTION
\*------------------------------------*/


.section-title--colophon {
    // let sticky title be dismissed early
    // should be a litter bigger than the 2 paragraphs
    margin-bottom: var(--section-title-dismiss-mb);
}

.colophon {
    --text-appear: 100%;
    --fade-in-end: 24rem;
    --fade-out-start: 60%;
    --_text-h: 80rem;
    --_p-top-offset: 20rem;
    --_sticky-top: calc(var(--main-header-mt) + 4rem + 10vh);

}

.colophon__wrapper {
    row-gap: 0.6rem;

    //compensate for early sticky dismissal
    margin-top: calc(var(--section-title-dismiss-mb) * -1);
}

.colophon__scuttle {
    display: none;

    @include respond-to(lg) {
        display: grid;
        grid-column: 1 / span 3;
        grid-row: 1 / span 2;
        height: 100%;
        margin-left: calc((var(--column-gap) - var(--body-p)) * -1);
        --scuttle-w: calc(100% + (var(--column-gap) - var(--body-p)));
        max-width: none;

        // First head: Objective
        .scuttle-card__title {
            h3 {
                @include text-style('title');
                color: var(--text-red);
            }
        }
    }
}

// Second head: Approach (fades)
.colophon__head {
    display: none;
    
    @include respond-to(lg) {
        display: block;
        grid-column: 1 / span 3;
        grid-row: 2 / span 1;
        margin-left: calc((var(--column-gap) - var(--body-p)) * -1);

        --flash-extend: -8rem;
        --flash-top: calc(50vh + 2rem); // magic no

        .flash__item {
            width: calc(33.33vw - 0.5rem); // magic no

            --flash-mt: 0 !important;
        }

        h3 {
            @include text-style('title');
            color: var(--text-inverted);
        }
    }
}

.colophon__text {
    grid-column: 1 / span var(--grid-cols);
    grid-row: 1 / span 4;
    height: 100%;

    // height of p and section title for sticky pos
    --section-title-h: calc(var(--main-header-mt) + 1rem * var(--line-height));
    --p1-h: calc((1rem * var(--line-height) * 7));
    --p2-h: calc((1rem * var(--line-height) * 7));

    p {
        margin-top: var(--p-mt);
        position: sticky;
        top: calc(var(--section-title-h) + var(--p-mt));
    }

    @include respond-to(lg) {
        height: 100%;
        grid-column: 6 / span 3;
        grid-row: 1 / span 1;
        min-height: var(--_text-h);

        // adjust page-grid
        padding: 0;

        p {
            margin-top: 0;
            top: var(--_sticky-top);
        }
    }
}

.colophon__text--1 {
    p {
        margin-bottom: calc(var(--p2-h) + var(--p-mt)); // add another p-mt to overcome safari rendering issue
    }

    @include respond-to(lg) {
        min-height: calc(var(--_text-h) + var(--_p-top-offset));

        p {
            margin-top: var(--_p-top-offset);
            margin-bottom: 0;
        }
    }

}

.colophon__text--1 + .colophon__text-fade {
    margin-top: var(--_p-top-offset);
    height: calc(100% - var(--_p-top-offset));
}

.colophon__text-fade {
    grid-column: 5 / span 5;
    grid-row: 1 / span 1;
}

.colophon__text--2,
.colophon__text--2 + .colophon__text-fade {
    grid-row: 3 / span 2;
    padding-top: var(--section-lg);

    @include respond-to(lg) {
        padding-top: 0;
        grid-row: 2 / span 1;
    }
}

.colophon__text--2 {
    p {
        top: calc(var(--section-title-h) + var(--p-mt) * 2 + var(--p1-h)); // add another p-mt to overcome safari rendering issue
        text-indent: var(--text-indent);
    }

    @include respond-to(lg) {
        p {
            text-indent: 0;
            top: min(100vh - 16rem, 30.8rem);
        }
    }
}

.colophon__versions {
    grid-column: 2 / span 2;
    grid-row: 2 / span 1;
    margin-top: 12rem;
    letter-spacing: -0.12ex;

    .flash {
        --flash-h: 6rem;
        --flash-extend: 6rem;
        --flash-top: 12rem;
    }

    @include respond-to(lg) {
        margin-top: 70rem;
        grid-column: 5 / span 1;
        grid-row: 1 / span 2;

        .flash {
            --flash-h: 8rem;
            --flash-extend: 16rem;
            --flash-top: 50vh;
        }
    }
}

.colophon__versions--content {
    grid-column: 4 / span 5;

    @include respond-to(lg) {
        margin-top: 64rem;
        grid-column: 6 / span 2;
    }
}

.colophon__snippets {
    grid-column: 2 / span 7;
    grid-row: 4 / span 1;
    margin-top: 28rem;

    .flash {
        --flash-h: 6rem;
        --flash-extend: 6rem;
        --flash-top: 21rem;
    }

    @include respond-to(lg) {
        margin-top: 20rem;
        grid-column: 7 / span 3;
        grid-row: 2 / span 1;

        .flash {
            --flash-h: 8rem;
            --flash-extend: 10rem;
            --flash-top: var(--_sticky-top);
        }
    }
}
