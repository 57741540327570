/*------------------------------------*\
  #Works SECTION
\*------------------------------------*/

.section-title--works {
    // let sticky title be dismissed early
    // should be a litter bigger than the 2 paragraphs
    margin-bottom: var(--section-title-dismiss-mb);
}

.works {
    --bg-title: var(--bg-grey);

    figure {
        border-top: 1px solid black;
    }
}

.works__sec-1 {
    //compensate for early sticky dismissal
    margin-top: calc(var(--section-title-dismiss-mb) * -1 + var(--p-mt));

    .works__scuttle,
    .works__years {
        grid-row: 2 / span 1;
    }

    @include respond-to(lg) {
        --text-appear: 90%;
        --fade-h: var(--text-appear);
        margin-top: 0;

        .works__text {
            height: var(--text-appear);

            p {
                position: sticky;
                top: calc(var(--main-header-mt) + var(--module-md) + 0.8rem);
            }
        }
    }
}

.works__sec-2 {
    --bg-title: var(--bg-red);
    --text-appear: 100%;

    .works__scuttle {
        margin-top: var(--module-md);
        grid-row: 2 / span 1;
    }

    .works__years {
        grid-row: 2 / span 1;
    }

    @include respond-to(lg) {
        .works__text {
            height: var(--text-appear);
        }

        .works__continue {
            position: sticky;
            top: calc(var(--main-header-mt) + var(--module-md) + 0.8rem);
        }

        .flash {
            --flash-top: 24rem;
        }

        .works__scuttle,
        .works__years {
            margin-top: -50vh;
        }
    }
}

.works__sec-3 {
    --bg-title: var(--bg-beige);

    .works__text {
        margin-top: var(--module-md);
        grid-row: 1 / span 2;
        height: 100%;

        p {
            position: sticky;
            top: calc(var(--main-header-mt) + 1rem * var(--line-height) + var(--p-mt));
        }
    }

    .works__scuttle {
        margin-top: var(--module-lg);
        grid-row: 3 / span 1;
    }

    .works__years {
        grid-row: 3 / span 1;
    }

    @include respond-to(lg) {
        // show text for 60% of the full view
        --text-appear: 60%;

        // fade in after 20% of scroll
        --fade-in-start: calc(20% + 10rem);
        --fade-in-end: calc(20% + 20rem);
        --fade-out-start: 70%;
        --fade-h: var(--text-appear);

        .works__text {
            height: var(--text-appear);
            margin-top: 0;
            grid-row: 1 / span 1;

            p {
                position: sticky;
                top: calc(var(--main-header-mt) + var(--module-md) + 3rem);
            }
        }

        .works__scuttle,
        .works__years {
            grid-row: 1 / span 1;
            margin-top: -50vh;
        }
    }
}

.works__clients {
    margin-top: 12rem;
    grid-row: 2 / span 1;
    grid-column: 2 / span 8;

    .flash {
        --flash-h: 6rem;
        --flash-extend: 6rem;
        --flash-top: 12rem;
    }

    @include respond-to(lg) {
        // start fadding in after 40rem
        margin-top: 70rem;
        grid-row: 1 / span 1;
        grid-area: right;

        .flash {
            --flash-top: 18.55rem;
        }
    }
}

.works__title {
    background: var(--bg-title);
    height: var(--scuttle-h);
    display: grid;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: var(--scuttle-pt);

    h3 {
        @include text-style('title');
        margin-bottom: 2rem;
    }
}

.works__item {
    background: var(--bg-grey);
}

.works__item-zoom {
    cursor: zoom-in;

    .is-zoomed & {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: var(--bg-default);
        z-index: map-get($z-index, modal);
        display: grid;
        cursor: zoom-out;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 2em;
    }

    @include respond-to(lg) {
        .is-zoomed & {
            padding: var(--body-p);
            grid-row-gap: var(--body-p);
            grid-template-rows: 1fr 1.2em;
        }
    }
}

.works__img {
    .is-zoomed & {
        place-self: stretch;
        position: relative;

        img {
            object-fit: cover;
            object-position: center center;
        }
    }

    @include respond-to(lg) {
        .is-zoomed & {
            img {
                object-fit: contain;
            }
        }
    }
}

// add body class
.has-zoom-img {
    overflow: hidden;

    // prevent jumping as scrollbar kicks in
    scrollbar-gutter: stable;
}

.works__caption {
    display: none;
    text-align: center;
    font-size: 0.8em;

    .is-zoomed & {
        display: block;
        place-self: center;
    }
}

.works__years {
    letter-spacing: -0.12ex;

    .flash__item {
        padding-left: 79%; // magic no
    }

    @include respond-to(lg) {
        .flash__item {
            padding-left: 0;
        }
    }
}

.works__years-inner {
    margin-top: var(--scuttle-h);
    z-index: map-get($z-index, work-years);
}