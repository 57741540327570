/*------------------------------------*\
  #awards SECTION
\*------------------------------------*/

.section-title--awards {
    // let sticky title be dismissed early
    // should be a litter bigger than the 2 paragraphs
    margin-bottom: var(--section-title-dismiss-mb);
}

.awards__wrapper {
    //compensate for early sticky dismissal
    margin-top: calc(var(--section-title-dismiss-mb) * -1);

    @include respond-to(lg) {
        > * {
            // Move colophon up
            // Assign to childs in order to make section title go earlier
            margin-bottom: -36rem; // adjust with --_p-top-offset
        }
    }
}

.awards__recognition {
    margin-top: 4rem;
    grid-column: 2 / span 5;
    grid-row: 1 / span 1;

    .flash {
        --flash-h: 6rem;
        --flash-extend: 6rem;
        --flash-top: calc(var(--main-header-mt) + 1rem * var(--line-height) + var(--p-mt));
    }

    @include respond-to(lg) {
        grid-column: 5 / span 4;

        .flash {
            --flash-top: var(--main-header-mt);
        }
    }
}

.awards__scuttle {
    grid-column: 1 / span var(--grid-cols);
    background: var(--bg-grey);
    margin-top: -60vh;

    @include respond-to(lg) {
        grid-column: 5 / span 5;
        grid-row: 1 / span 1;
        margin-top: 0;

        // do not cover full width
        --scuttle-w: 86%;
        place-self: start;
    }
}