/*------------------------------------*\
  #continue

  Add Text boxes with continue reading
\*------------------------------------*/

.continue {
    cursor: pointer;

    p {
        span {
            display: inline-block;

            @for $i from 1 through 50 {
                &:nth-child(#{$i + 1}) {
                    --trans-delay: #{$i * 0.025s};
                }
            }
        }

        &[aria-hidden="true"] {
            display: none;
        }

        &.is-read {
            span {
                transition: transform 1.8s $trans-func--ease-out var(--trans-delay, 0s),
                            opacity 1.8s $trans-func--ease-out var(--trans-delay, 0s);
                transform: translate3d(-40rem, 0, 0);
                opacity: 0;
            }
        }
    }

    p + p {
        span:first-child {
            text-indent: var(--text-indent);
        }
    }
}

.continue__btn {
    display: flex;
    padding-left: var(--text-indent);
    margin: 0.4rem 0;
    overflow: hidden;

    svg {
        width: 1.75em;
        height: 1.25em;
        --transform: rotate(360deg);
        transform-origin: center 100%;
    }

    span {
        margin-left: 1ex;
    }

    &:hover {
        svg {
            animation: to-transform 0.6s $trans-func--ease-in-out both;
        }
    }
}

.continue__btn--restart {
    i {
        transform: scaleY(-1) scalex(-1);
    }
}
