/*------------------------------------*\
  #ROOT
\*------------------------------------*/

/**
 * Default definition of custom properties
 */

:root {
    // Add custom properties for size scale
    @include create-custom-properties($size-scale, false, 'size-');

    // Define max with of content container
    --container-w: calc(100vw - 2 * var(--body-p));

    @supports (width: min(1rem, 1rem)) {
        // --container-w: min(80vw + 12rem, 100vw - 2 * var(--body-p));
    }

    // Add token vars
    @include create-custom-properties($text-colors, false, 'text-');
    @include create-custom-properties($bg-colors, false, 'bg-');
    @include create-custom-properties($border-colors, false, 'border-');

    // Add custom properties for ui definitions
    @include create-custom-properties($ui-props, true);

    // Add border radius
    @include create-custom-properties($border-radius, false, 'radius-');

    // Focus style
    @include create-custom-properties($focus-style, false, 'focus-');

    // @media (prefers-color-scheme: dark) {
    //     // invert colors for darkmode
    //     @include create-custom-properties($text-colors-dark, false, 'text-');
    //     @include create-custom-properties($bg-colors-dark, false, 'bg-');
    // }
}

::selection {
    color: var(--text-inverted);
    background-color: var(--bg-grey);
}
