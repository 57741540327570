/*------------------------------------*\
  #FLASH

  Show a flash of an fix positioned item while scrolling by
\*------------------------------------*/

.flash {
    --flash-fade: 10rem; // fade time
    --flash-extend: 5rem; // overlapping with other flashes
    --flash-top: 15rem;
    --flash-mt: 0px;

    position: relative;
    height: calc(var(--flash-h, 100%) + (var(--flash-extend) * 2));
    margin-top: calc(var(--flash-extend) * -1);
    margin-bottom: calc(var(--flash-extend) * -1 * 2);
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) var(--flash-fade), rgba(0, 0, 0, 1) calc(100% - var(--flash-fade)), rgba(0, 0, 0, 0) 100%);
    pointer-events: none;
}

.flash--ratio {
    --aspect-ratio: 0.75;
    @include aspect-ratio();
    width: 100%;
    height: auto;
    padding: var(--flash-extend) 0;
}

.flash--l {
    --aspect-ratio: 0.8 !important; // 5/4
}

.flash--c {
    --aspect-ratio: 1 !important;
}

.flash--p {
    --aspect-ratio: 1.333 !important; // 3/4
}

.flash--p2 {
    --aspect-ratio: 1.41 !important; // din
}

.flash--p3 {
    --aspect-ratio: 1.5 !important; // 2/3
}

.flash__item {
    position: fixed;
    top: var(--flash-top);
    width: var(--scuttle-w, auto);
    text-align: center;
    margin-top: var(--flash-mt);

    a {
        @include no-touch() {
            pointer-events: all;
        }
    }

    @for $i from 1 through 50 {
        .flash:nth-child(#{$i + 1}) & {
            --flash-mt: #{$i * $line-height-default * 1rem};
        }
    }
}

.flash__item--inverted {
    color: var(--text-inverted);

    @include respond-to(lg) {
        color: inherit;
    }
}