/*------------------------------------*\
  #fade
\*------------------------------------*/

.fade-in-out,
.fade-in,
.fade-out {
    pointer-events: none;
    height: var(--fade-h, 100%);
    position: relative;
    display: none;
    // z-index: map-get($z-index, fade);

    //outline: 2px solid pink;
    // --bg-fade: rgba(0,0,0, 0.6);

    @include respond-to(lg) {
        display: block;
    }
}

.fade-in-out {
    background: linear-gradient(to bottom,
            var(--bg-default),
            var(--bg-default) var(--fade-in-start, 10rem),
            var(--bg-fade) var(--fade-in-end, 40rem),
            var(--bg-fade) var(--fade-out-start, 50%),
            var(--bg-default) calc(100% - var(--fade-out-end, 25%)),
            var(--bg-default));
}


.fade-out {
    background: linear-gradient(to bottom,
            var(--bg-fade),
            var(--bg-fade) var(--fade-out-start, 50%),
            var(--bg-default) calc(100% - var(--fade-out-end, 25%)),
            var(--bg-default));
}

.fade-in {
    background: linear-gradient(to bottom,
            var(--bg-default),
            var(--bg-default) var(--fade-in-start, 10rem),
            var(--bg-fade) var(--fade-in-end, 40rem));
}
