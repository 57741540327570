///*----------------------------------*\
//  #ANIMATIONS
//\*----------------------------------*/

@keyframes to-fade {
    0% {
        opacity: 0.001;
    }
}

@keyframes to-transform {
    100% {
        transform: var(--transform);
    }
}

@keyframes from-to-transform {
    0% {
        transform: var(--from-transform);
    }
    100% {
        transform: var(--transform);
    }
}

@keyframes pulse {
    0%, 100% {
        transform: var(--transform);
    }
    50% {
        transform: var(--transform) var(--transform-animation);
    }
}