/*------------------------------------*\
  #SECTION NUM
\*------------------------------------*/

// Add scrolling number which sticks to the top
.section-num {
    font-style: normal;
    position: sticky;
    top: var(--main-header-mt);
    counter-increment: section;
    pointer-events: none;
    height: 2rem;
    margin-bottom: -2rem;

    z-index: map-get($z-index, section-num);

    &:not(:first-child) {
        margin-top: var(--section-num-mt);
    }

    // Hide if main nav is hovered
    .main-header:hover ~ .main & {
        visibility: hidden;
    }

    // hide last item on mobile
    .colophon ~ & {
        display: none;
    }

    &:after {
        @include text-style('num');
        grid-column: 2 / span 5;
        content: counter(section);
        display: block;
        position: relative;
        width: var(--main-header-item-w),
    }

    @for $i from 2 through 10 {
        &:nth-of-type(#{$i}) {
            &:after {
                margin-left: calc(#{$i - 1} * var(--main-header-item-w));
            }
        }
    }

    @include respond-to(lg) {
        // hide last item on mobile
        .colophon ~ & {
            display: grid;
        }
    }
}