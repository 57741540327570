/*------------------------------------*\
  #SCUTTLE

  Sticky font stack
\*------------------------------------*/

// Add scrolling number which sticks to the top
.stack {
    // Top position from viewport edge
    --stack-pos-top: var(--section-md);

    // Scrolling distance before fade kicks in
    --stack-stay-h: 50rem;

    // Default definitions for stack items
    --item-i: 0;
    --item-h: 1rem * $line-height-default;
    --item-offset: var(--item-h);

    display: grid;

    // Stack items
    // All items are placed on top of each other inside the same grid cell
    // Stacking is done with increased top padding and predined additional offset
    // Sticking is done is done with increased top position
    > * {
        --stack-top: calc(var(--stack-pos-top) - (var(--item-i) * var(--item-h)));
        position: sticky;
        top: var(--stack-top);
        padding-top: calc((var(--item-offset) + var(--item-h)) * var(--item-i));
        grid-column: 1;
        grid-row: 1;
        place-self: stretch center;

        // add item count
        @for $i from 2 through 30 {
            &:nth-child(#{$i}) {
                --item-i: #{$i - 1};
            }
        }
    }

    // Space put below stack to make stack stay
    &:before {
        content: '';
        grid-column: 1;
        grid-row: 2;
        height: var(--stack-stay-h);
    }
}

// Add a fading gradient to cover stack before scrolling out of viewport
.stack--fade {

    // Height of fading gradient cover
    --stack-fade-cover-h: 100vh;

    // Scrolling distance before fade kicks in
    --stack-stay-h: 5rem;

    // compensate for stack cover
    margin-bottom: -100vh;

    // Fix issue where items would scroll out before fully cover
    // if item height is bigger than 100vh
    // Fix overflow with clip path to keep stickiness
    clip-path: inset(0 0 0 0);

    > * {
        // Limit item height
        height: 100vh;
    }

    // Cover up stack with a fade cover
    &:after {
        content: '';
        position: relative;
        width: 100%;
        transform: translate3d(0,0,0);
        height: calc(var(--stack-fade-cover-h) + 100vh);
        background: linear-gradient(to bottom, var(--bg-fade), var(--bg-default) var(--stack-fade-cover-h));
    }
}

.stack--display {
    // center four lines of text in viewport
    --stack-pos-top: (50vh - (var(--font-size-display) * #{$line-height-display} * 2));

    // extend item height to display text size
    --item-h: calc(var(--font-size-display) * #{$line-height-display});
}

.stack--centered {
    align-items: center;
}
