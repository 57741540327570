/*------------------------------------*\
  #MAIN Header
\*------------------------------------*/

.main-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    z-index: map-get($z-index, main-header);
}

.main-header__nav {
    display: none;

    li {
        counter-increment: navitem;
    }

    @include respond-to(md) {
        grid-column: 2;
        display: block;
        margin-top: var(--main-header-mt);

        ol {
            display: flex;
        }
    }
}

.main-header__item {
    display: block;
    position: relative;
    opacity: 0;
    pointer-events: all;

    &:before {
        @include text-style('num');
        content: counter(navitem);
        display: block;
        position: relative;
        width: var(--main-header-item-w),
    }

    span {
        display: block;
        position: absolute;
        top: 0;
        padding-top: var(--main-header-pt);
        visibility: hidden;
        width: max-content;
    }

    &:hover {
        opacity: 1;

        span {
            visibility: visible;
        }
    }
}